@tailwind base;
@tailwind components;
@tailwind utilities;

/* *{
	outline: 1px solid magenta;
} */

@layer base {
	:root {
		/* --font-sans: here if you have one */
		/* --font-mono: here if you got it... */

		/* SLATE THEME ( SHADCN/UI THEME ) */

		--background: 0 0% 100%;
		--background-secondary: 0 0% 90%;
		--foreground: 222.2 84% 4.9%;

		/* prefixed with foreground because it should look good on the background */
		--foreground-destructive: 345 82.7% 40.8%;

		--muted: 210 40% 93%;
		--muted-foreground: 215.4 16.3% 30%;

		--popover: 0 0% 100%;
		--popover-foreground: 222.2 84% 4.9%;

		--card: 0 0% 100%;
		--card-foreground: 222.2 84% 4.9%;

		--border: 214.3 31.8% 75%;
		--input: 214.3 31.8% 91.4%;
		--input-invalid: 0 84.2% 60.2%;

		--primary: 222.2 47.4% 11.2%;
		--primary-foreground: 210 40% 98%;

		--secondary: 210 20% 83%;
		--secondary-foreground: 222.2 47.4% 11.2%;

		--accent: 210 40% 90%;
		--accent-foreground: 222.2 47.4% 11.2%;

		--destructive: 0 70% 50%;
		--destructive-foreground: 210 40% 98%;

		--ring: 215 20.2% 65.1%;

		--radius: 0.5rem; 

		--gain: 142.1 76.2% 36.3%;
		--loss: 0 0% 96.1%; 

		--chart-1: 220 70% 50%;
		--chart-2: 160 60% 45%;
		--chart-3: 30 80% 55%;
		--chart-4: 280 65% 60%;
		--chart-5: 340 75% 55%;

		/* BLACK THEME (TS HERO) 

		--background: 240 10% 3.9%;
		--foreground: 0 0% 98%;

		--muted: 240 3.7% 15.9%;
		--muted-foreground: 240 5% 64.9%;

		--popover: 240 10% 3.9%;
		--popover-foreground: 0 0% 98%;

		--card: 240 10% 3.9%;
		--card-hovered: 240 10% 6.9%;
		--card-foreground: 0 0% 98%;

		--border: 240 3.7% 15.9%;
		--input: 240 3.7% 15.9%;

		--primary: 211 61% 48%;
		--primary-foreground: 0 0% 98%;

		--secondary: 240 3.7% 15.9%;
		--secondary-foreground: 0 0% 98%;

		--accent: 240 3.7% 15.9%;
		--accent-foreground: ;

		--destructive: 0 62.8% 30.6%;
		--destructive-foreground: 0 85.7% 97.3%;

		--error-label: 0 92.2% 64.9%;

		--ring: 240 3.7% 15.9%;
		--radius: 0.5rem; 

		--gain: 142.1 76.2% 36.3%;
		--loss: 0 0% 96.1%; */



        /* orange color scheme */
		/* --background: "0 0% 100%",
        "--foreground": "222.2 47.4% 11.2%",
        "--muted": "210 40% 96.1%",
        "--muted-foreground": "215.4 16.3% 46.9%",
        "--popover": "0 0% 100%",
        "--popover-foreground": "222.2 47.4% 11.2%",
        "--border": "214.3 31.8% 91.4%",
        "--input": "214.3 31.8% 91.4%",
        "--card": "0 0% 100%",
        "--card-foreground": "222.2 47.4% 11.2%",
        "--primary": "20.5 90.2% 48.2%",
        "--primary-foreground": "210 40% 98%",
        "--secondary": "210 40% 96.1%",
        "--secondary-foreground": "222.2 47.4% 11.2%",
        "--accent": "210 40% 96.1%",
        "--accent-foreground": "222.2 47.4% 11.2%",
        "--destructive": "0 100% 50%",
        "--destructive-foreground": "210 40% 98%",
        "--ring": "215 20.2% 65.1%",
        "--radius": "0.5rem", */

	}

	.dark {
	/* BLACK THEME (TS HERO) */

	/* --background: 240 10% 3.9%;
    --foreground: 0 0% 98%;

    --muted: 240 3.7% 15.9%;
    --muted-foreground: 240 5% 64.9%;

    --popover: 240 10% 3.9%;
    --popover-foreground: 0 0% 98%;

    --card: 240 10% 3.9%;
    --card-hovered: 240 10% 6.9%;
    --card-foreground: 0 0% 98%;

    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;

    --primary: 211 61% 48%;
    --primary-foreground: 0 0% 98%;

    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;

    --accent: 240 3.7% 15.9%;
    --accent-foreground: ;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 85.7% 97.3%;

    --error-label: 0 92.2% 64.9%;

    --ring: 240 3.7% 15.9%;
    --radius: 0.5rem; 

	--gain: 142.1 76.2% 36.3%;
	--loss: 0 0% 96.1%;
	*/

	/* SLATE THEME ( SHADCN/UI THEME ) */
		
		--background: 222.2 84% 4.9%;
		--background-secondary: 222.2 84% 15%;
		--foreground: 210 40% 98%;

		/* prefixed with foreground because it should look good on the background */
		--foreground-destructive: -4 84% 60%;

		--muted: 217.2 32.6% 12%;
		--muted-foreground: 215 20.2% 65.1%;

		--popover: 222.2 84% 4.9%;
		--popover-foreground: 210 40% 98%;

		--card: 222.2 84% 4.9%;
		--card-foreground: 210 40% 98%;

		--border: 217.2 32.6% 17.5%;
		--input: 217.2 32.6% 17.5%;
		--input-invalid: 0 62.8% 30.6%;

		--primary: 210 40% 98%;
		--primary-foreground: 222.2 47.4% 11.2%;

		--secondary: 217.2 20% 24%;
		--secondary-foreground: 210 40% 98%;

		--accent: 217.2 32.6% 10%;
		--accent-foreground: 210 40% 98%;

		--destructive: 0 60% 40%;
		--destructive-foreground: 0 85.7% 97.3%;

		--ring: 217.2 32.6% 60%; 
		--radius: 0.5rem; 

		--gain: 142.1 76.2% 36.3%;
		--loss: 0 0% 96.1%;

		--chart-1: 220 70% 50%;
		--chart-2: 160 60% 45%;
		--chart-3: 30 80% 55%;
		--chart-4: 280 65% 60%;
		--chart-5: 340 75% 55%;

	}
}

@layer base {
	* {
		@apply border-border;
	}
	body {
		@apply bg-background text-foreground;
	}
	html {
		scroll-behavior: smooth;
	}
}

.animated-feature-cards::before {
	@apply pointer-events-none absolute select-none rounded-xl opacity-0 transition-opacity duration-300 hover:opacity-100;
	background: radial-gradient(
	  350px circle at var(--x) var(--y),
	  #3aecf8 0,
	  #5295dc 25%,
	  rgba(255, 255, 255, 0) 50%,
	  transparent 80%
	);
	z-index: -1;
	content: '';
	inset: -1px;
}

.lampcontainer {
	position: relative;
	margin-top: -330px;
	contain: layout;
	height: 420px;
  }
  
  .lamp {
	width: 100%;
	height: 400px;
	position: relative;
	margin-bottom: -400px;
	pointer-events: none;
	user-select: none;
	--first: conic-gradient(
	  from 90deg at 80% 50%,
	  hsl(var(--background)),
	  hsl(var(--background)),
	  var(--bottom)
	);
	--second: conic-gradient(
	  from 270deg at 20% 50%,
	  var(--bottom),
	  hsl(var(--background)),
	  hsl(var(--background))
	);
	-webkit-mask-image: radial-gradient(100% 50% at center center, black, transparent);
	background-image: var(--first), var(--second);
	background-position-x: 1%, 99%;
	background-position-y: 0%, 0%;
	background-size:
	  50% 100%,
	  50% 100%;
	opacity: 1;
	transform-origin: center center;
	background-repeat: no-repeat;
  }
  
  .lamp::after {
	content: '';
	position: absolute;
	inset: 0px 0px 50%;
	overflow: hidden;
	opacity: 0.1;
	-webkit-mask-image: radial-gradient(140px 50% at 50% 100%, black, transparent);
	mask-image: radial-gradient(140px 50% at 50% 100%, black, transparent);
  }

  .underlined {
	position: relative;
	text-decoration: none !important;
	white-space: nowrap;
}

.underlined:focus {
	outline: none;
	text-decoration: none !important;
}

.underlined:after {
	content: '';
	height: 2px;
	transform: scaleX(0);
	transition: transform 0.25s ease;
	transform-origin: left;
	left: 0;
	bottom: -4px;
	width: 100%;
	display: block;
	position: absolute;
}

.underlined:hover:after,
.underlined:focus:after,
.active.underlined:after {
	background-color: hsl(var(--primary));
	transform: scaleX(1);
}

input {
	color-scheme: dark;
}